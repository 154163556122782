import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.scss'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueFullPage from 'vue-fullpage.js'
import './registerServiceWorker'

Vue.use(VueAwesomeSwiper)
Vue.use(VueFullPage)
Vue.config.productionTip = false
// Vue.prototype.aos = AOS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

let key = 'G-W7PEDCXSPM'
switch (window.location.host) {
  case 'topwin006.com':
    key = 'G-S237E2035L'
    break
  case 'topwin005.com':
    key = 'G-X0TV9PDL0Y'
    break
  case 'topwin004.com':
    key = 'G-PMP25G6KBE'
    break
  case 'topwin003.com':
    key = 'G-VW84QG1NY8'
    break
  case 'topwin002.com':
    key = 'G-08DKG4TYJW'
    break
  case 'topwin001.com':
    key = 'G-PCBPNR6HP0'
    break
  case 'topwining.com':
    key = 'G-JWS8W62GWZ'
    break
  case 'topwinnav1.com':
    key = 'G-6TW55DL240'
    break
  case 'topwinnav2.com':
    key = 'G-KRBWVZ2Z41'
    break
  case 'topwinnav3.com':
    key = 'G-NY3T96NZR2'
    break
  case 'detopwin.com':
    key = 'G-E6JPJ23YED'
    break
  case 'estopwin.com':
    key = 'G-J7D79WRT90'
    break
  case 'dktopwin.com':
    key = 'G-QCJ9QPQLRX'
    break
  case 'autopwin.com':
    key = 'G-N4D30CSC46'
    break
  case 'mxtopwin.com':
    key = 'G-8EYF20Y9Z3'
    break
  case 'nltopwin.com':
    key = 'G-1WTVTFDZEJ'
    break
}

Vue.use(VueGtag, {
  config: { id: key }
}, router)
